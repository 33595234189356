import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-72b3ba66"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "impressum" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_page_header_fixed = _resolveComponent("page-header-fixed")!
  const _component_DatenschutzBase = _resolveComponent("DatenschutzBase")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_page_header_fixed),
    _createElementVNode("main", null, [
      _createVNode(_component_DatenschutzBase)
    ])
  ]))
}