
import { defineComponent } from 'vue';
import PageHeaderFixed from '@/components/Allgemein/PageHeaderFixed.vue';
import ImpressumBase from '@/components/Impressum/ImpressumBase.vue';

export default defineComponent({
  name: 'Impressum',
  components: {
    ImpressumBase,
    PageHeaderFixed
  }
});

